import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import EmailIcon from "@mui/icons-material/Email";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import MapsUgcIcon from "@mui/icons-material/MapsUgc";
import LogoutIcon from "@mui/icons-material/Logout";
import vslogo from "../../assets/venturesathi-logo.png";
import vslogowhite from "../../assets/vsathiWhitelogo.png";
import { Badge, Switch } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import noprofile from "../../assets/no-profile.png";
import { RxDashboard } from "react-icons/rx";
import { MdOutlineExplore } from "react-icons/md";
import { FiBookmark } from "react-icons/fi";
import { HiOutlineDocument, HiOutlineDocumentDuplicate } from "react-icons/hi";
import vlivlogo from "../../assets/Vliv logo 1.png";
import { notifySuccess, notifyWarning } from "../../Constrant/toastAlert";
import axios from "axios";
import SocialMedia from "../SocialMedia/SocialMedia";
import SocialMediaDrawer from "../SocialMedia/SocialMediaDrawer";
import Notification from "../Notification/Notification";
import { FaRegBell } from "react-icons/fa6";
import { localFrontendVlivdomain, vlivDomain } from "../../Constrant/api";

let userInfo = null;
let userrole = null;

const userInfoCookie = Cookies.get("userinfo");

if (userInfoCookie) {
  try {
    userInfo = JSON.parse(userInfoCookie);
    userrole = userInfo?.userrole;
  } catch (error) {
    console.error("Error parsing userinfo cookie:", error);
  }
}

const pages = [
  {
    path: "/",
    name: "WELCOME",
    // icon: <MdDashboard />,
  },
  {
    path: "/kms",
    name: "KMS",
    // icon: <MdDashboard />,
    subRoutes: [
      {
        path: "/kms/myDocs",
        name: "MY DOCS",
        icon: <HiOutlineDocument />,
      },
      {
        path: "/kms/allDocs",
        name: "ALL DOCS",
        icon: <HiOutlineDocumentDuplicate />,
      },
    ],
  },
  {
    path: "/lms",
    name: "LMS",
    // icon: <ExploreIcon />,
    subRoutes: [
      {
        path: "/lms/dashboard",
        name: "DASHBOARD",
        icon: <RxDashboard />,
      },
      {
        path: "/lms/explore",
        name: "EXPLORE",
        icon: <MdOutlineExplore />,
      },
      {
        path: "/lms/favourite",
        name: "FAVOURITE",
        icon: <FiBookmark />,
      },

      userrole == "masteradmin"
        ? {
          path: "lms/admin/courses",
          name: "COURSE",
          // icon: <MdDashboard />,
        }
        : {
          path: "lms/view/courses",
          name: "COURSE",
          // icon: <MdDashboard />,
        },
    ],
  },
];

const others = [
  {
    name: "Notifications",
    icon: <NotificationsIcon />,
    path: "/notifications",
  },
  // {
  //   name: "Messages",
  //   icon: <MapsUgcIcon />,
  //   path: "/messages",
  // },
];

function Navbar({ theme, toggleTheme }) {
  const isProduction = process.env.NODE_ENV === 'production';
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [profileEl, setProfileEl] = React.useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenDetailsMenu = (event) => {
    setProfileEl(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleCloseDetailsMenu = () => {
    setProfileEl(null);
  };

  const navigateTo = (url) => {
    navigate(url);
  };

  const handleLogout = () => {
    Cookies.remove("companytoken",
      {
        domain: isProduction ? '.vliv.app' : 'localhost',
        path: '/'
      });
    Cookies.remove("userinfo",
      {
        domain: isProduction ? '.vliv.app' : 'localhost',
        path: '/'
      });
    navigate("/signin");
  };

  // Sahid social media drawer and notification start
  const [openChatDrawer, setOpenChatDrawer] = React.useState(null);
  const [openNotificationDrawer, setOpenNotificationDrawer] = React.useState(null);

  const handleOpenChatDrawer = () => {
    setOpenChatDrawer(true);
  };
  const handleOpenNotification = () => {
    setOpenNotificationDrawer(true);
  };
  // Sahid social media drawer notification end
  return (
    <div
      style={{
        backgroundColor: "#F4FAFF",
        // boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px",

      }}
    >
      <Box minWidth="100%">
        <Toolbar
          disableGutters
          sx={{
            minWidth: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: 0, // Remove padding on x-axis

          }}
        >
          <Box sx={{ ml: 1 }}>
            <Typography
              variant="h6"
              noWrap
              component="a"
              sx={{

                display: { xs: "flex", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                textDecoration: "none",
                // ml: -4.8, // Remove left margin
              }}
            >
              <img
                src={theme ? vlivlogo : vlivlogo}
                alt="vs-logo"
                width="90"
                height="25"
                onClick={() => window.location.assign(`${process.env.NODE_ENV == "production" ? vlivDomain : localFrontendVlivdomain}/frontend/apps`)}
              />
            </Typography>
          </Box>
          {/* <Typography
            variant="h5"
            noWrap
            component="a"
            sx={{
           
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              textDecoration: "none",
              ml: 0, // Remove left margin
            }}
          >
            <img
              src={vlivlogo}
              alt="vs-logo"
              width="100"
              height="30"
              onClick={() => window.location.assign("https://klms.vliv.app")}
            />
          </Typography> */}
          <Box>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                // mr:-5
              }}
            >
              {/* For social media icon */}
              {/* Sahid's social media & Notification start */}
              <IconButton
                onClick={handleOpenChatDrawer}
                className={`navbaricon ${theme === "light" ? "light" : "dark"}`}
              >
                <MapsUgcIcon />
              </IconButton>

              <SocialMediaDrawer
                open={openChatDrawer}
                handleClose={() => setOpenChatDrawer(false)}
              />

              <IconButton
                onClick={handleOpenNotification}
                className={`navbaricon ${theme === "light" ? "light" : "dark"}`}
              >
                <FaRegBell />

              </IconButton>

              <Notification
                open={openNotificationDrawer}
                handleClose={() => setOpenNotificationDrawer(false)}
              />
              {/* Sahid's social media & Notification end */}

              {/* {others?.map((other, ind) => (
              <IconButton
                key={ind}
                sx={{
                  mr: 1,
                  color: theme ? "white" : "black",
                  backgroundColor:
                    location.pathname === other.path && !theme
                      ? "azure"
                      : "transparent",
                  "&:hover": {
                    backgroundColor: "#f0f0f0",
                  },
                  border:
                    location.pathname === other.path && theme
                      ? "1px solid white"
                      : "",
                }}
                onClick={() => navigateTo(other.path)}
              >
                {other.name == "Notifications" ? (
                  <Badge badgeContent={""}>{other.icon}</Badge>
                ) : (
                  other.icon
                )}
              </IconButton>
            ))} */}

              <IconButton onClick={handleOpenUserMenu}>
                <Avatar
                  alt="Profile Image"
                  src={!userData.clientimage ? noprofile : userData.clientimage}
                />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElUser}
                sx={{ mt: "7px" }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <div
                  style={{
                    width: "280px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "9px",
                  }}
                  onClick={() => {
                    handleCloseUserMenu();
                    // navigate("/profile/details");
                  }}
                >
                  <div>
                    <Typography>
                      <img
                        src={
                          !userData.clientimage ? noprofile : userData.clientimage
                        }
                        alt="user-profile"
                        style={{ borderRadius: "50%" }}
                        height={100}
                        width={100}
                      />
                    </Typography>
                  </div>

                  <h3>
                    {userData.userrole == "masteradmin"
                      ? userData.clientname
                      : userData.username}
                  </h3>
                  <p>Role: {userData.userrole ? userData.userrole : "N/A"}</p>
                  <p>
                    {userData.userrole == "masteradmin"
                      ? userData.clientemail
                      : userData.email}
                  </p>
                  {/* <p>Emp: {userData.userid ? userData.userid : "N/A"}</p> */}

                  <Button
                    variant="outlined"
                    endIcon={<LogoutIcon />}
                    sx={{ borderRadius: "10px", color: "#0B4A6F" }}
                    onClick={handleLogout}
                  >
                    Logout
                  </Button>
                </div>
              </Menu>
            </Box>
            <Box
              sx={{
                display: { xs: "flex", md: "none", justifyContent: "flex-end" },
                flexGrow: 1,
              }}
            >
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenDetailsMenu} sx={{ p: 0 }}>
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={profileEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(profileEl)}
                onClose={handleCloseDetailsMenu}
              >
                {others?.map((other, ind) => (
                  <MenuItem key={ind}>
                    <Button
                      onClick={() => {
                        navigate(other.path);
                        handleCloseDetailsMenu();
                      }}
                      sx={{
                        color: "black",
                        backgroundColor:
                          location.pathname === other.path
                            ? "azure"
                            : "transparent",
                        "&:hover": {
                          backgroundColor: "#f0f0f0",
                        },
                      }}
                    >
                      {other.icon} &nbsp; {other.name}
                    </Button>
                  </MenuItem>
                ))}
                {/* <MenuItem>
                <Switch
                  checked={theme}
                  onChange={toggleTheme}
                  inputProps={{ "aria-label": "controlled" }}
                />{" "}
                <span>Change Theme</span>
              </MenuItem> */}
                <MenuItem>
                  <Button sx={{ color: "black" }}>
                    <Avatar
                      alt="Profile Image"
                      src={
                        !userData.clientimage ? noprofile : userData.clientimage
                      }
                    />{" "}
                    &nbsp;
                    <span>
                      {userData.userrole == "masteradmin"
                        ? userData.clientname
                        : userData.username}
                    </span>
                  </Button>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <Button>
                    <LogoutIcon />
                    <span>Logout</span>
                  </Button>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </Box>
    </div>
  );
}

export default Navbar;
